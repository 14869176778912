import React, {useState, useEffect, Component} from 'react';
import {Elements, useStripe} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { subscribeUser } from '../../store/actions/authAction';
// import { useNavigate } from 'react-router-dom';
import { Navigate} from "react-router-dom";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SetupSuccess from './SetupSuccess';

// const stripePromise = loadStripe('pk_test_4AFaH4csocBSqy2bD1z3IjGa00ugkd9EY7');
const stripePromise = loadStripe('pk_live_1aR8W7INnlRFhridB2zxnT0500lmcf9P2J');

const Wrapper = (props) => {
    console.log('PORP GUYS: ', props)
    // const navigate = useNavigate();

    // useEffect(() => {
    //     // rerun logic depending on isCreatingSubscription value
    //     navigate('/setup-success')
    //   }, [props.isCreatingSubscription]);

    return (
    <Elements stripe={stripePromise}>
        <PaymentStatus {...props} />
    </Elements>
    )
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        // isCreatingSubscription: state.auth.isCreatingSubscription,
        isSubscribingUser: state.auth.isSubscribingUser,
        userSubscriptionPlan: state.auth.userSubscriptionPlan,
        packageSelected: state.auth.packageSelected,
        error: state.auth.error
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        // createTrialSubscription: (id) => dispatch(createTrialSubscription(id)),
        subscribeUser: (data) => dispatch(subscribeUser(data)),
    }
  }

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Wrapper)

const PaymentStatus = (props) => {
  const {t} = props
  const stripe = useStripe();
  console.log('STRIPe: ', stripe)
  const [message, setMessage] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    console.log('clientSecret: ', clientSecret)
    if (!clientSecret) {
        setRedirect(true)
        return
    }
    // Retrieve the SetupIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
          console.log('paymentIntent1: ', paymentIntent)
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
            case 'succeeded':
                // setMessage('Payment Successful!');
                setPaymentSuccess(true)
                // setRedirect(true);
                // const userId = props.loggedInUser.id
                // const packageKey = props.packageSelected.key
                // const data = {
                //     packageKey,
                //     userId,
                //     paymentIntentId: paymentIntent.id
                // }
                // // console.log('packageKey: ', packageKey)
                // props.subscribeUser(data)
                // props.createTrialSubscription(userId)
            break;
            case 'processing':
                setMessage("Processing payment details. We'll update you when processing is complete.");
            break;
            case 'requires_payment_method':
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                setMessage(t('pay_setup_fail_label'));
            break;
            default:
                setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe]);


  return <SetupFinal redirect={redirect} paymentSuccess={paymentSuccess} message={message} {...props} />
  
};

class SetupFinal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stripeError: null,
            // redirect: false
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { error, userSubscriptionPlan, isSubscribingUser } = this.props
    //     // if (prevProps.isCreatingSubscription !== isCreatingSubscription) {
    //     //     if (!isCreatingSubscription && error === null) {
    //     //         console.log('subscr:' , userSubscriptionPlan)
    //     //         // alert('go to success page')
    //     //         // this.props.navigate('/setup-sucess')
    //     //         this.setState({redirect: true})
    //     //     }
    //     // }
    //     if (prevProps.isSubscribingUser !== isSubscribingUser) {
    //         if (!isSubscribingUser && error === null) {
    //             console.log('subscr:' , userSubscriptionPlan)
    //             // alert('go to success page')
    //             // this.props.navigate('/setup-sucess')
    //             this.setState({redirect: true})
    //         }
    //     }
    // }

    render() {
        // console.log('navigate3: ', this.props.navigate)
        if (this.props.paymentSuccess) {
            return <SetupSuccess />
        }
        return (
            <div>
                <h3>{this.props.message}</h3>
                {/* {
                    this.props.isSubscribingUser && 
                    <p>creating new subscription plan...</p>
                } */}
                {
                    this.props.redirect &&
                    <Navigate to='/app' replace={true} />
                }
            </div>
        )
    }
}