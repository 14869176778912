import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';

class DTLandingPage extends Component {

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                <h2>Redirecting to app page...</h2>
                {
                    // this.state.accRedirect &&
                    <Navigate to='/app' replace={true} />
                }
            </div>
        )
    }
}
  
export default DTLandingPage