import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class UseTerms extends Component {

    componentDidMount() {
        document.documentElement.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor:'powderblue', padding: 10}}>
                <h1 style={{alignSelf: 'center', textAlign: 'center'}}>TERMS OF USE OF DIEGOTRAINER.COM AND DIEGO TRAINER APPS</h1>
                <p>Last updated: 22 Apr, 2022</p>
                <h2>Purpose</h2>
                <span>
                    Welcome to DiegoTrainer Sites (the Site) which include our website on domain DIEGOTRAINER.COM, and our iOS and android apps named DT Personal Training App and DT Training App. This Terms of Use Agreement ("Agreement") sets forth the agreement between Diego Trainer and each user ("User", "Your" or "You") governing the use by you of the Site and the DiegoTrainer Privacy Policy (Privacy Policy). Please read this agreement carefully and fully before using the Site or disclosing to us any personal information. By using the Site, or disclosing to us any personal information: (i) you agree that you have read and understand the terms of this Agreement, (ii) you accept and agree to be bound by the terms of this Agreement, and (iii) you accept and agree to abide by all laws and regulations applicable to the subject matter of this Agreement. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT ACCESS OR OTHERWISE USE THE SITE OR DISCLOSE TO US ANY PERSONAL INFORMATION.
                </span>
                <h2>Warranty Disclaimer</h2>
                <span>
                    The Site and the materials and products on the Site are provided as is and without warranties of any kind, whether express or implied. To the fullest extent permissible pursuant to applicable law, DiegoTrainer disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and non-infringement. DiegoTrainer does not represent or warrant that the functions contained in the Site will be uninterrupted or error-free, that the defects will be corrected, or that the Site or the server that makes the Site available are free of viruses or other harmful components. DiegoTrainer does not make any warrantees or representations regarding the use of the materials in the Site in terms of their correctness, accuracy, adequacy, usefulness, timeliness, reliability or otherwise. Applicable law may not allow limitations or exclusions on warranties, so the above limitation or exclusion may not apply to you. Limitation of Liability DiegoTrainer shall not be liable for any special or consequential damages that result from the use of, or the inability to use, the materials on the Site or the performance of the programs on the Site, even if DiegoTrainer has been advised of the possibility of such damages. Applicable law may not allow the limitation of exclusion of liability or incidental or consequential damages, so the above limitation or exclusion may not apply to you.
                </span>
                <h2>Typographical Errors</h2>
                <span>
                    In the event that DiegoTrainer product or service is mistakenly listed at an incorrect price, DiegoTrainer reserves the right to refuse or cancel any orders placed for product or service listed at the incorrect price. DiegoTrainer reserves the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, DiegoTrainer shall issue a credit to your credit card account in the amount of the incorrect price.
                </span>
                <h2>Term Termination</h2>
                <span>
                    These terms and conditions are applicable to you upon your accessing the site and/or completing the registration. These terms and conditions, or any part of them, may be terminated by DiegoTrainer without notice at any time, for any reason. The provisions relating to Warranty Disclaimer, Limitation of Liability, Indemnification shall survive any termination.
                </span>
                <h2>Notice</h2>
                <span>
                    DiegoTrainer may deliver notice to you by means of e-mail, a general notice on the Site, or by other reliable method to the address you have provided to DiegoTrainer
                </span>
                <h2>Indemnification</h2>
                <span>
                    You agree to indemnify, defend, and hold harmless DiegoTrainer, its officers, directors, employees, agents, licensors and suppliers (collectively the Service Providers) from and against all losses, expenses, damages and costs, including reasonable attorney fees, resulting from any violation of these terms and conditions or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the Site using your Internet account.
                </span>
                <h1>PRIVACY POLICY</h1>
                <span>
                    DiegoTrainer respects your privacy and permits you to control the treatment of your personal information.
                </span>
                <h2>USER CONTENT</h2>
                <span>
                    You grant DiegoTrainer a license to use the materials you post to the Site. By posting, downloading, displaying, performing, transmitting, or otherwise distributing information or other content (User Content) to the Site, you are granting DiegoTrainer, its affiliates, officers, directors, employees, consultants, agents, and representatives a license to use User Content in connection with the operation of the Internet business of DiegoTrainer, its affiliates, officers, directors, employees, consultants, agents, and representatives, including without limitation, a right to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate, and reformat User Content. You will not be compensated for any User Content. You agree that DiegoTrainer may publish or otherwise disclose your name in connection with your User Content. By posting User Content on the Site, you warrant and represent that you own the rights to the User Content or are otherwise authorized to post, distribute, display, perform, transmit, or otherwise distribute User Content.
                </span>
                <h2>GOVERNING LAW</h2>
                <span>
                These Terms of Use shall be construed in accordance with and governed by the laws of United States of America, without reference to their rules regarding conflicts of law.
                </span>
                <h2>
                    COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS
                </h2>
                <span>
                    When accessing or using the Site, you agree to obey the law and to respect the intellectual property rights of others. Your use of the Site is at all times governed by and subject to laws regarding copyright ownership and use of intellectual property. You agree not to upload, download, display, perform, transmit, or otherwise distribute any information or content (collectively, Content) in violation of any third party's copyrights, trademarks, or other intellectual property or proprietary rights. You agree to abide by laws regarding copyright ownership and use of intellectual property, and you shall be solely responsible for any violations of any relevant laws and for any infringement of third party rights caused by any Content you provide or transmit, or that is provided or transmitted using your User ID. The burden of proving that any Content does not violate any laws or third party rights rests solely with you.
                </span>
                <h2>
                    NO WARRANTIES
                </h2>
                <span>
                    DIEGOTRAINER HEREBY DISCLAIMS ALL WARRANTIES. DIEGOTRAINER IS MAKING THE SITE AVAILABLE AS IS WITHOUT WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SITE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, DIEGOTRAINER EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. DIEGOTRAINER DOES NOT WARRANT THAT THE SITE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
                </span>
                <h2>
                    LIMITED LIABILITY
                </h2>
                <span>
                    DIEGOTRAINER'S LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL DIEGOTRAINER BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS OR SERVICES PROVIDED TO YOU BY DIEGOTRAINER. This limitation shall apply regardless of whether the damages arise out of breach of contract, tort, or any other legal theory or form of action.
                </span>
                <h2>
                PROHIBITED USES
                </h2>
                <span>
                DiegoTrainer imposes certain restrictions on your permissible use of the Site. You are prohibited from violating or attempting to violate any security features of the Site, including, without limitation, (a) accessing content or data not intended for you, or logging onto a server or account that you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of the Site, or any associated system or network, or to breach security or authentication measures without proper authorization; (c) interfering or attempting to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Site, overloading, flooding, spamming, œmail bombing, crashing; (d) using the Site to send unsolicited e-mail, including, without limitation, promotions, or advertisements for products or services; (e) forging any TCP/IP packet header or any part of the header information in any e-mail or in any posting using the Site; or (f) attempting to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to a human-perceivable form any of the source code used by DiegoTrainer in providing the Site. Any violation of system or network security may subject you to civil and/or criminal liability.
                </span>
                <h2>
                SEVERABILITY; WAIVER
                </h2>
                <span>
                If, for whatever reason, a court of competent jurisdiction finds any term or condition in these Terms of Use to be unenforceable, all other terms and conditions will remain unaffected and in full force and effect. No waiver of any breach of any provision of these Terms of Use shall constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.
                </span>
                <h2>
                NO LICENSE.
                </h2>
                <span>
                Nothing contained on the Site should be understood as granting you a license to use any of the trademarks, service marks, or logos owned by DiegoTrainer or by any third party.
                </span>
                <h2>
                MODIFICATIONS
                </h2>
                <span>
                    DiegoTrainer may, in its sole discretion and without prior notice, (a) revise these Terms of Use; (b) modify the Site; and (c) discontinue the Site at any time. DiegoTrainer shall post any revision to these Terms of Use to the Site, and the revision shall be effective immediately on such posting. You agree to review these Terms of Use and other online policies posted on the Site periodically to be aware of any revisions. You agree that, by continuing to use or access the Site following notice of any revision, you shall abide by any such revision.
                </span>
                <h2>
                ACKNOWLEDGEMENT.
                </h2>
                <span>
                BY USING AND ACCESSING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE AND AGREE TO BE BOUND BY THEM.
                </span>
            </div>
        )
    }
}
  
export default withTranslation()(UseTerms)