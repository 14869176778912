import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate} from "react-router-dom";
import { Button, Divider, Grid, TextField } from '@mui/material';
import { DateTime } from "luxon";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { getLoggedInUser } from '../../store/actions/authAction';

class Account extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 0,
      redirect: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { token } = this.props
    if (token !== prevProps.token) {
      if (!token) {
        this.setState({redirect: true})
      }
    }
  }

  componentDidMount() {
    const { loggedInUser } = this.props
    if (!loggedInUser.id) {
      this.setState({redirect: true})
      return
    }
    window.scrollTo(0, 0)
    this.props.getLoggedInUser()
  }
  
  render() {
    const { loggedInUser, userSubscriptionPlan, screenWidth, userLang } = this.props
    // console.log('fdsdf', DateTime)
    const { t } = this.props
    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 20}}>
          <h1>{t('account_label')}</h1>
          <div style={{width: screenWidth < 700 ? '100%' : '50%'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <p style={{fontWeight: '600'}}>{t('first_name_label')}</p>
                <p>{loggedInUser.firstName}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <p style={{fontWeight: '600'}}>{t('last_name_label')}</p>
                <p>{loggedInUser.lastName}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <p style={{fontWeight: '600'}}>{t('email_label')}</p>
                <p>{loggedInUser.email}</p>
            </div>
            <p style={{color: 'red', textAlign: 'center', lineHeight: 1.5}}>{t('edit_acc_note')}</p>
          </div>

          <div style={{height: 1, backgroundColor: 'dimgrey', width: screenWidth < 700 ? '100%' : '50%'}} />

          <h1>{t('subscription_plan_title')}</h1>
          <div style={{width: screenWidth < 700 ? '100%' : '50%'}}>
            {
                !userSubscriptionPlan.id ?
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{textAlign: 'center'}}>{t('no_subscription_plan_label')}</p>
                    <Button
                        component={Link}
                        to="/app/plan-selection"
                    >{t('get_started_btn')}</Button>
                </div> :
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p style={{fontWeight: '600'}}>{userSubscriptionPlan.status === 'Active' ? t('cur_plan_lbl') : t('latest_plan_lbl')}</p>
                        <p style={{textAlign: 'right'}}>{userSubscriptionPlan.service_key === 1 ? t('monthly_plan_label') : userSubscriptionPlan.service_key === 2 ? '3-Month Plan' : userSubscriptionPlan.service_key === 3 ? '6-Month Plan' : userSubscriptionPlan.service_key === 4 ? t('yearly_plan_label') : 'not found'}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p style={{fontWeight: '600'}}>{t('status_label')}</p>
                        <p style={{fontWeight: 'bold', textAlign: 'right', color: userSubscriptionPlan.status === 'Active' ? 'green' : 'red'}}>{userSubscriptionPlan.status === 'Active' ? t('status_active_label') : userSubscriptionPlan.status === 'Inactive' ? t('status_inactive_label') : userSubscriptionPlan.status === 'Expired' ? t('status_expired_label') : null}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p style={{fontWeight: '600'}}>{t('expiry_date_label')}</p>
                        <p style={{textAlign: 'right'}}>{!userSubscriptionPlan.expiry_date ? '-' : DateTime.fromISO(userSubscriptionPlan.expiry_date).setLocale(userLang).toLocaleString(DateTime.DATE_MED)}</p>
                    </div>
                </div>
            }
          </div>
          {
            this.state.redirect &&
            <Navigate to='/app' replace={true} />
          }
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      loggedInUser: state.auth.loggedInUser,
      userSubscriptionPlan: state.auth.userSubscriptionPlan,
      token: state.auth.token,
      screenWidth: state.auth.screenWidth,
      userLang: state.auth.userLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getLoggedInUser: () => dispatch(getLoggedInUser()),
      
  }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Account)