import { Alert, Button } from '@mui/material';
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import i18next from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// const stripePromise = loadStripe('pk_test_4AFaH4csocBSqy2bD1z3IjGa00ugkd9EY7', {
//     stripeAccount: 'acct_1Fn1kUAMFvGl6xGz'
// });

class PaymentForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stripeError: null,
            isLoading: false
        }
        // this.stripePromise = loadStripe('pk_test_4AFaH4csocBSqy2bD1z3IjGa00ugkd9EY7', {
        this.stripePromise = loadStripe('pk_live_1aR8W7INnlRFhridB2zxnT0500lmcf9P2J', {
            stripeAccount: 'acct_1Fn1kUAMFvGl6xGz',
            locale: i18next.language
        });
    }

    handleSubmit = input => (event) => {
        console.log('input: ', input)

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        const { stripe, elements } = input;
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        this.setState({isLoading: true}, async () => {

            const { clientSecret } = this.props
            console.log('clide2: ', clientSecret)
            // const result = await stripe.confirmSetup({
            //     //`Elements` instance that was used to create the Payment Element
            //     elements,
            //     confirmParams: {
            //         return_url: "http://localhost:3000/setup-status",
            //     },
            // });

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    // return_url: "/payment-success",
                    // return_url: 'http://localhost:3000/app/payment-status'
                    return_url: 'https://www.diegotrainer.com/app/payment-status'
                },
            });
            console.log('result: ', result)
            // alert('SUCCESS')
        
            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                console.log('stripeError: ', result.error.message);
                this.setState({stripeError: result.error.message})
            } else {
                // alert('SUCCESS')
                console.log('result2: ', result)
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            }
            this.setState({isLoading: false})
        })
    };

  render() {
    
    const { clientSecret, t } = this.props
    console.log('clientSecret: ', clientSecret)
    const options = {
        // passing the client secret obtained in step 2
        clientSecret,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
    };
    if (!clientSecret) {
        return null
    }
    // const stripePromise = loadStripe('pk_live_1aR8W7INnlRFhridB2zxnT0500lmcf9P2J', {
    //     stripeAccount: 'acct_1Fn1kUAMFvGl6xGz',
    //     locale: 'th'
    // });
    return (
        <Elements stripe={this.stripePromise} options={options}>
            <ElementsConsumer>
                {
                    ({stripe, elements}) => {
                        return (
                            <form>
                                    <PaymentElement />
                                    <br />
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Button
                                            variant="contained"
                                            color='primary'
                                            onClick={this.handleSubmit({ stripe, elements })}
                                            disabled={!stripe || this.state.isLoading}
                                        >
                                            {this.state.isLoading ? t('processing_label') : t('submit_label')}
                                        </Button>
                                    </div>
                                    {/* <p>{t('wont_charge_label')}</p> */}
                                    {
                                        this.state.stripeError &&
                                        <p style={{color: 'red'}}>{this.state.stripeError}</p>
                                    }
                            </form>
                        )
                    }
                }
                
            </ElementsConsumer>
        </Elements>
    );
  }
}

export default withTranslation()(PaymentForm);
