import React, { Component } from 'react';
import { Button, Card, Grid } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { CheckRounded } from '@mui/icons-material';
import { connect } from 'react-redux';
import { setCurRoute, setPackageSelect } from '../../store/actions/authAction';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class PlanSelection extends Component {
    constructor(props) {
        super(props)
        const { t } = props
        this.state = {
          accRedirect: false
        }
        // this.priceTableArr = [
        //   {key: 1, numMonths: 1, title: t('one_month'), priceTh: 800, priceUs: 20, priceBr: 100, bgColor: 'silver'},
        //   {key: 2, numMonths: 3, title: t('three_months'), priceTh: 2040, priceUs: 55, priceBr: 270, bgColor: 'silver'},
        //   {key: 3, numMonths: 6, title: t('six_months'), priceTh: 3840, priceUs: 100, priceBr: 500, bgColor: 'silver'},
        //   {key: 4, numMonths: 12, title:t('one_year'), priceTh: 7200, priceUs: 190, priceBr: 950, bgColor: 'silver'},
        // ]
        // this.programIncludes = [
        //   {key: 1, value: t('program_feature_one')},
        //   {key: 2, value: t('program_feature_two')},
        //   {key: 3, value: t('program_feature_three')},
        //   {key: 4, value: t('program_feature_four')},
        //   {key: 5, value: t('program_feature_five')},
        //   {key: 6, value: t('program_feature_six')},
        //   {key: 7, value: t('program_feature_seven')},
        // ]
        // this.programDescription = t('program_description')
    }
  
    componentDidMount() {
        this.props.setCurRoute('/app/plan-selection')
        const { userSubscriptionPlan } = this.props
        if (userSubscriptionPlan.id && userSubscriptionPlan.status === 'Active') {
          this.setState({accRedirect: true})
          return
        }
        window.scrollTo(0, 0)
    }

  render() {
    const { t } = this.props
    console.log('props: ', this.props )
    const priceTableArr = [
      {key: 1, numMonths: 1, title: t('one_month'), priceTh: 999, priceUs: 29.99, priceBr: 149.90, bgColor: 'silver'},
      {key: 2, numMonths: 3, title: t('three_months'), priceTh: 2500, priceUs: 76.00, priceBr: 379.90, bgColor: 'silver'},
      {key: 3, numMonths: 6, title: t('six_months'), priceTh: 4790, priceUs: 143.99, priceBr: 719, bgColor: 'silver'},
      {key: 4, numMonths: 12, title: t('one_year'), priceTh: 8990, priceUs: 269, priceBr: 1349, bgColor: 'silver'},
    ]
    const programIncludes = [
      {key: 1, value: t('program_feature_one')},
      // {key: 2, value: t('program_feature_two')},
      // {key: 3, value: t('program_feature_three')},
      // {key: 4, value: t('program_feature_four')},
      {key: 5, value: t('program_feature_five')},
      {key: 6, value: t('program_feature_six')},
      // {key: 7, value: t('program_feature_seven')},
    ]
    const programDescription = t('program_description')
    return (
        <div style={{backgroundColor:'black', padding: 10, paddingBottom: 20}}>
          <h2 style={{color: 'tomato', textAlign: 'center', alignSelf: 'center'}}>{t('choose_plan')}</h2>
          {/* <h3 style={{alignSelf:'center', textAlign:'center', color: 'white', lineHeight: 1.5}}>{programDescription}</h3> */}
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            // wrap="nowrap" // --> add this line to disable wrap
            // // sx={{ overflow: "auto" }} // --> add scrollbar when the content inside is overflowed
            spacing={2}
          >
            {
              priceTableArr.map((card) => (
                <Grid
                  key={card.key.toString()}
                  // style={{alignSelf:'center'}}
                  item lg={3} md={4} sm={6}
                  xs='auto'
                >
                  <Card
                    // xs='auto'
                    sx={{
                      width: 250,
                      backgroundColor: card.bgColor,
                      // height: 250,
                      margin: 1,
                      padding: 1,
                      display: 'flex',
                      flexDirection:'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // alignSelf: 'center',
                    }}
                  >
                    {/* {card.icon} */}
                    <span style={{fontSize: 25, alignSelf:'center', textAlign:'center', fontWeight:'bold'}}>{card.title}</span>
                    {/* <span style={{alignSelf:'center', textAlign:'center'}}>{this.programDescription}</span> */}
                    <br />
                    <div style={{marginRight: 10, marginLeft: 10}}>
                      {
                        programIncludes.map(item => {
                          return (
                            <div key={item.key.toString()} style={{display: 'flex', alignSelf: "flex-start", justifyContent: 'flex-start', marginBottom: 5}}>
                              <CheckRounded color='primary' sx={{color: 'green', marginRight: .5}} />
                              <span style={{lineHeight: 1.5}}> {item.value}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                    {
                     this.props.userCurrency === 'brl' ?
                     <span style={{fontWeight: "bold", marginTop: 20, textDecoration: 'line-through', color: 'darkred' }}>R$ {(card.priceBr*1.5).toLocaleString().replace(/,/g , "__").replace(/\./g, ',').replace(/__/g, '.')}</span> :
                     this.props.userCurrency === 'usd' ?
                     <span style={{fontWeight: "bold", marginTop: 20, textDecoration: 'line-through', color: 'darkred'}}>{(card.priceUs*1.5).toLocaleString()} USD</span> :
                     this.props.userCurrency === 'thb' ?
                     <span style={{fontWeight: "bold", marginTop: 20, textDecoration: 'line-through', color: 'darkred'}}>{(card.priceTh*1.5).toLocaleString()} THB</span> :
                      null
                    }
                    {
                     this.props.userCurrency === 'brl' ?
                     <span style={{fontWeight: "bold", marginTop: 20}}>R$ {card.priceBr.toLocaleString().replace(/,/g , "__").replace(/\./g, ',').replace(/__/g, '.')}</span> :
                     this.props.userCurrency === 'usd' ?
                     <span style={{fontWeight: "bold", marginTop: 20}}>{card.priceUs.toLocaleString()} USD</span> :
                     this.props.userCurrency === 'thb' ?
                     <span style={{fontWeight: "bold", marginTop: 20}}>{card.priceTh.toLocaleString()} THB</span> :
                      null
                    }
                    {/* <span style={{fontWeight: "bold", marginTop: 20}}>{card.price.toLocaleString()} THB</span> */}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom:10}}>
                      <Button
                        variant='contained'
                        // sx={{alignSelf:'center'}}
                        onClick={() => {
                          this.props.setPackageSelect(card)
                        }} // this.props.navigate('/register', { selectedOption: card})}
                        component={Link}
                        to="/app/register"
                        // color="danger"
                        // size="lg"
                        // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                        // target="_blank"
                        // rel="noopener noreferrer"
                      >
                        {/* <i className="fas fa-play" /> */}
                        {t('buy_label')}
                      </Button>
                    </div>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
          {
            this.state.accRedirect &&
            <Navigate to='/app/user-account' replace={true} />
          }
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
      userSubscriptionPlan: state.auth.userSubscriptionPlan,
      userCurrency: state.auth.userCurrency,
      userLang: state.auth.userLang,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      setCurRoute: (val) => dispatch(setCurRoute(val)),
      setPackageSelect: (data) => dispatch(setPackageSelect(data)),
    }
  }

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PlanSelection)
