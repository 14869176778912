import React, { Component } from 'react';
import { Box, Button, Container, Grid, Paper, TextField } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import { connect } from 'react-redux';
import { getSecret, register, setCurRoute, setRedirect, subscribeUser } from '../../store/actions/authAction';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { CheckRounded } from '@mui/icons-material';
// import { withRouter } from '../../withRouter';
// import { withRouter } from '../../helpers/withRouter';

class RegisterPage extends Component {

  constructor(props) {
    super(props)
    const { t } = this.props
    this.state = {
      pageIndex: 0,
      firstName: '',
      lastName: '',
      email: "",
      password: "",
      clientSecret: null,
      paymentMethod: 0
      // nameOnCard: "",
    }
    // this.programDescription = t('program_description')
    // this.priceTableArr = [
    //   {key: 1, numMonths: 1, title: t('one_month'), priceTh: 800, priceUs: 20, priceBr: 100, bgColor: 'silver'},
    //   {key: 2, numMonths: 3, title: t('three_months'), priceTh: 2040, priceUs: 55, priceBr: 270, bgColor: 'silver'},
    //   {key: 3, numMonths: 6, title: t('six_months'), priceTh: 3840, priceUs: 100, priceBr: 500, bgColor: 'silver'},
    //   {key: 4, numMonths: 12, title:t('one_year'), priceTh: 7200, priceUs: 190, priceBr: 950, bgColor: 'silver'},
    // ]
    // this.programIncludes = [
    //   {key: 1, value: t('program_feature_one')},
    //   // {key: 2, value: t('program_feature_two')},
    //   {key: 3, value: t('program_feature_three')},
    //   {key: 4, value: t('program_feature_four')},
    //   {key: 5, value: t('program_feature_five')},
    //   {key: 6, value: t('program_feature_six')},
    //   {key: 7, value: t('program_feature_seven')},
    // ]
  }
  
  // fetchSecret = () => {
  //   fetch(url + 'getsecret', {
  //       method: "GET",
  //       headers: {
  //           'Content-Type': 'application/json',
  //           // 'Authorization': `${token}`
  //   }})
  //   .then(res => res.json())
  //   .then(res => {
  //       if (res.error) {
  //           throw(res);
  //       }
  //       // dispatch(getUserStatsSuccess(res));
  //       this.setState({clientSecret: res})
  //   })
  //   .catch(error => {
  //       console.log(error)
  //       // dispatch(getUserStatsError(error));
  //   })
  // }

  componentDidMount() {
    document.documentElement.scrollTo(0, 0);
    const { userSubscriptionPlan, loggedInUser } = this.props

    if (userSubscriptionPlan.id && userSubscriptionPlan.status === 'Active') {
      this.setState({accRedirect: true})
      return
    }
    this.props.setRedirect(false)
    console.log('this.props.: ', this.props)
    this.props.setCurRoute('/app/register')
    
    // if (loggedInUser.tookFreeTrial !== 1) {
    //   this.setState({pageIndex: 2})
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isRegistering, isAutoLoggingIn, isGettingSecret, error, token, loggedInUser, isLoggingIn, userSubscriptionPlan } = this.props
    console.log("isRegistering: ", isRegistering)
    if (prevProps.isRegistering !== isRegistering) {
      if (!isRegistering && !error) {
        console.log('loged user', loggedInUser)
        const { stripeClientSecret } = loggedInUser
        this.setState({clientSecret: stripeClientSecret}, () => {
          this.setState({pageIndex: 1})
        })
      }
      else if (error) {
        alert(error)
      }
    }

    if (prevProps.isGettingSecret !== isGettingSecret) {
      if (!isGettingSecret && !error) {
        console.log('loged user', loggedInUser)
        const { stripeClientSecret } = loggedInUser
        this.setState({clientSecret: stripeClientSecret}, () => {
          this.setState({pageIndex: 1})
        })
      }
    }

    if (prevProps.isLoggingIn !== isLoggingIn) {
      if (!isLoggingIn && error === null) {
          if (userSubscriptionPlan.id && userSubscriptionPlan.status === 'Active') {
            this.setState({accRedirect: true})
          }
      }
    }

    if (prevProps.token !== token) {
      if (!token) {
        this.setState({pageIndex: 0})
      }
    }

    if (prevProps.isAutoLoggingIn !== isAutoLoggingIn) {
      if (!isAutoLoggingIn && error === null) {
          if (userSubscriptionPlan.id && userSubscriptionPlan.status === 'Active') {
            this.setState({accRedirect: true})
          }
      }
    }

  }

  setFirstName = e => {
    const firstName = e.target.value
    this.setState({firstName})
  }

  setLastName = e => {
    const lastName = e.target.value
    this.setState({lastName})
  }

  setEmail = e => {
    const email = e.target.value
    this.setState({email})
  }

  setPassword = e => {
    const password = e.target.value
    this.setState({password})
  }

  handleRegister = () => {
    const { t, userLang, userCurrency } = this.props
    const packageSelected = this.getPackageSelected()
    const { firstName, lastName, email, password } = this.state
    if (firstName === '' || lastName === '' || email === '' || password === '') {
      alert(t('fill_required_fields_label'))
      return
    }
    const data = {
      firstName,
      lastName,
      email,
      password,
      packageKey: packageSelected.key,
      userLang,
      currency: userCurrency
    }
    console.log('data123: ', data)
    this.props.register(data)
  }

  handleGetSecret = () => {
    const { loggedInUser, userCurrency } = this.props
    const { id } = loggedInUser
    const packageSelected = this.getPackageSelected()
    const data = {
      userId: id,
      packageKey: packageSelected.key,
      currency: userCurrency
    }
    console.log('data222: ', data)
    this.props.getSecret(data)
  }

  renderFreeTrialSection = () => {
    const {t} = this.props
    return (
      <div  style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <h1 style={{textAlign: 'center'}}>{t('free_signup_title')}</h1>
        <p>{t('signup_info_one')}</p>
        <p>{t('signup_info_two')}</p>
      </div>
    )
  }

  getPackageSelected = () => {
    const { t } = this.props
    const priceTableArr = [
      {key: 1, numMonths: 1, title: t('one_month'), priceTh: 999, priceUs: 29.99, priceBr: 149.90, bgColor: 'silver'},
      {key: 2, numMonths: 3, title: t('three_months'), priceTh: 2500, priceUs: 76, priceBr: 379.90, bgColor: 'silver'},
      {key: 3, numMonths: 6, title: t('six_months'), priceTh: 4790, priceUs: 143.99, priceBr: 719, bgColor: 'silver'},
      {key: 4, numMonths: 12, title: t('one_year'), priceTh: 8990, priceUs: 269, priceBr: 1349, bgColor: 'silver'},
    ]
    return this.props.packageSelected.title ? this.props.packageSelected : priceTableArr[2] // setting 6 months plan as ddefault package seected just in case
  }

  renderEnTitle = (packageSelected) => {
    return 'You choose ' + packageSelected.title + '! '
  }

  renderThTitle = (packageSelected) => {
    return 'คุณเลือกแพกเกจ ' + packageSelected.title + '! '
  }

  renderPTTitle = (packageSelected) => {
    return 'Você escolheu ' + packageSelected.title + '! '
  }

  renderBottomPayDescription = () => {
    const { userLang } = this.props
    if (userLang === 'th') {
      return (
        <h4 style={{lineHeight: 1.5}}>
          หากคุณยังไม่เคยลงทะเบียนกับเรา กรอกฟอร์มด้านล่างเพื่อสมัครก่อนชำระเงิน หากคุณมีบัญชีแล้ว <Link style={{color: 'dodgerblue'}} onClick={() => this.props.setLoginModal(true)}>ล็อคอินที่นี่</Link>
        </h4>
      )
    }
    else if (userLang === 'pt') {
      return (
        <h4 style={{lineHeight: 1.5}}>
          Se você ainda não possui uma conta conosco, cadastre-se abaixo antes de prosseguir para a página de pagamento. Se já possui uma conta, faça <Link style={{color: 'dodgerblue'}} onClick={() => this.props.setLoginModal(true)}>Login</Link> primeiro.
        </h4>
      )
    }
    else {
      return (
        <h4 style={{lineHeight: 1.5}}>
          If you don't have an account with us yet, sign up below before proceeding to the payment page. If you do, <Link style={{color: 'dodgerblue'}} onClick={() => this.props.setLoginModal(true)}>Log In</Link> first.
        </h4>
      )
    }
    
  }

  renderSelectedPack = () => {
    console.log('LOCATION: ', this.props)
    const { t } = this.props
    // const { packageSelected } = this.props
    const packageSelected = this.getPackageSelected()
    const programDescription = t('program_description')
    const programIncludes = [
      {key: 1, value: t('program_feature_one')},
      // {key: 2, value: t('program_feature_two')},
      // {key: 3, value: t('program_feature_three')},
      // {key: 4, value: t('program_feature_four')},
      {key: 5, value: t('program_feature_five')},
      {key: 6, value: t('program_feature_six')},
      // {key: 7, value: t('program_feature_seven')},
    
    ]
    return (
      <div  style={{display: 'flex', flexDirection: 'column'}}>
        <h1 style={{textAlign: 'center'}}>
          {
            this.props.userLang === 'pt' ?
            this.renderPTTitle(packageSelected) :
            this.props.userLang === 'th' ?
            this.renderThTitle(packageSelected) :
            this.renderEnTitle(packageSelected)
          }
          <Link to='/app/plan-selection' style={{marginTop: 10, textAlign: 'center'}}>{t('change_plan')}</Link>
        </h1>
        <span style={{textAlign: 'center', lineHeight: 1.5}}>{programDescription}</span>
        <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3>{t('includes_label')}</h3>
            <div style={{marginRight: 10, marginLeft: 10}}>
              {
                programIncludes.map(item => {
                  return (
                    <div style={{display: 'flex', alignSelf: "flex-start", justifyContent: 'flex-start', marginBottom: 5}}>
                      <CheckRounded color='primary' sx={{color: 'green', marginRight: .5}} />
                      <span style={{lineHeight: 1.5}}> {item.value}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column',}}>
            <div style={{backgroundColor: 'black', borderRadius: 100, paddingRight: 30, paddingLeft: 30, paddingBottom: 20, paddingTop: 20, display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}}>
              {/* <h3 style={{color: 'white', textAlign: 'center'}}>{packageSelected.price.toLocaleString()} THB</h3> */}
              {
                this.props.userCurrency === 'brl' ?
                <span style={{fontWeight: "bold", color: 'white', textAlign: 'center'}}>R$ {packageSelected.priceBr}</span> :
                this.props.userCurrency === 'usd' ?
                <span style={{fontWeight: "bold", color: 'white', textAlign: 'center'}}>$ {packageSelected.priceUs}</span> :
                this.props.userCurrency === 'thb' ?
                <span style={{fontWeight: "bold", color: 'white', textAlign: 'center'}}>฿ {packageSelected.priceTh.toLocaleString()}</span> :
                null
              }
            </div>
            <span style={{marginTop: 10, textAlign: 'center'}}><b>{t('billing_schedule')}</b> {packageSelected.key === 1 ? t('monthly') : packageSelected.key === 2 ? t('every_three_months') : packageSelected.key === 3 ? t('every_six_months') : t('yearly')}</span>
            <span style={{marginTop: 10, textAlign: 'center', color: 'darkred'}}>{t('cancel_anytime_text')}</span>
          </div>
        </div>
        {
          this.state.pageIndex === 0 && this.props.loggedInUser.id ?
          <h3 style={{alignSelf: 'center', textAlign: "center"}}>{t('click_btn_text')}</h3> :
          this.state.pageIndex === 0 ?
          <h3 style={{alignSelf: 'center', textAlign: "center"}}>{this.renderBottomPayDescription()}</h3> :
          <h3 style={{alignSelf: 'center', textAlign: "center"}}>{t('finish_pay_text')}</h3>
        }
      </div>
    )
  }
  
  render() {
    // const { loggedInUser } = this.props
    console.log('this.props123', this.props)
    const { t } = this.props
    
    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 20}}>
          {/* {
            this.renderFreeTrialSection()
          } */}
          {
            this.renderSelectedPack()
          }
          <div style={{
            display: 'flex',
            width: '85%',
            height: 50,
            backgroundColor: 'black',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
              
            <p style={{color:'white', fontWeight: 'bold', alignSelf: 'center'}}>{this.state.pageIndex === 0 ? t('acc_details_label') : this.state.pageIndex === 1 ? t('payment_details_label') : null}</p>
          </div>
          <br />
          {
            this.state.pageIndex === 0 ?
            <div style={{width: '85%'}}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        label={t('first_name_label')}
                        disabled={this.props.loggedInUser.id !== undefined}
                        onChange={this.setFirstName}
                        value={this.props.loggedInUser.id ? this.props.loggedInUser.firstName : this.state.firstName}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        label={t('last_name_label')}
                        disabled={this.props.loggedInUser.id !== undefined}
                        onChange={this.setLastName}
                        value={this.props.loggedInUser.id ? this.props.loggedInUser.lastName : this.state.lastName}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                    />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                      <TextField
                          label={t('email_label')}
                          disabled={this.props.loggedInUser.id !== undefined}
                          onChange={this.setEmail}
                          value={this.props.loggedInUser.id ? this.props.loggedInUser.email : this.state.email}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                          type={'email'}
                      />
                  </Grid>
              </Grid>
              <br />
              {
                !this.props.loggedInUser.id &&
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label={t('password_label')}
                            disabled={this.props.loggedInUser.id !== undefined}
                            onChange={this.setPassword}
                            value={this.state.password}
                            InputProps={{ disableUnderline: true, fullWidth: true }}
                            fullWidth
                            type={'password'}
                        />
                    </Grid>
                </Grid>
              }
              {
                !this.props.loggedInUser.id &&
                <br />
              }
              {/* <h3>Select payment method</h3>
              <input
                type="radio"
                name="payment"
                value="Card"
                id="card"
                checked={this.state.paymentMethod === 1}
                onChange={() => this.setState({paymentMethod: 1})}
              />
              <label htmlFor="card" style={{marginRight: 20}}>Card</label>
              <input
                type="radio"
                name="payment"
                value="Pix"
                id="pix"
                checked={this.state.paymentMethod === 2}
                onChange={() => this.setState({paymentMethod: 2})}
              />
              <label htmlFor="pix">Pix</label> */}
            </div> :
            <div style={{width: '85%'}}>
              {
                this.state.clientSecret !== null ?
                <PaymentForm
                  clientSecret={this.state.clientSecret}
                  loggedInUser={this.props.loggedInUser}
                  packageSelected={this.props.packageSelected}
                  subscribeUser={this.props.subscribeUser}
                /> :
                null
              }
              
            </div>
          }
          <br />
          {
            this.state.pageIndex === 0 &&
            <Button
              variant="contained"
              color='primary'
              disabled={this.props.isGettingSecret || this.props.isRegistering}
              onClick={() => {
                if (this.props.loggedInUser.id) {
                  this.handleGetSecret()
                }
                else {
                  this.handleRegister()
                }
              }}
            >
              {this.props.isRegistering || this.props.isGettingSecret ? t('processing_label') : t('next_btn_label')}
            </Button>
          }
          {/* {
            this.state.pageIndex === 0 &&
            <p>{t('wont_charge_label')}</p>
          } */}
          {
            this.state.accRedirect &&
            <Navigate to='/app/user-account' replace={true} />
          }
          {/* {
            this.state.redirect &&
            <Navigate to='/app' replace={true} />
          } */}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      loggedInUser: state.auth.loggedInUser,
      isRegistering: state.auth.isRegistering,
      isGettingSecret: state.auth.isGettingSecret,
      isLoggingIn: state.auth.isLoggingIn,
      userSubscriptionPlan: state.auth.userSubscriptionPlan,
      userCurrency: state.auth.userCurrency,
      userLang: state.auth.userLang,
      token: state.auth.token,
      packageSelected: state.auth.packageSelected,
      error: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      register: (data) => dispatch(register(data)),
      setCurRoute: (value) => dispatch(setCurRoute(value)),
      getSecret: (data) => dispatch(getSecret(data)),
      subscribeUser: (data) => dispatch(subscribeUser(data)),
      setRedirect: (val) => dispatch(setRedirect(val))
      
  }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(RegisterPage)