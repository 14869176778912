import React, { Component } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class Login extends Component {
    render() {
      const { t } = this.props
    return (
        <div style={{display: 'flex', alignSelf: 'center', alignItems: 'center', backgroundColor: 'black', alignSelf: 'center', flexDirection: 'column', padding: 40}}>
            <>
              <img src={require('../../img/logo.png')} style={{width:200, height: 60}} />
            </>
            <h1 style={{color: 'white'}}>{t('log_in_title')}</h1>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12} >
                    <TextField
                        label={t('email_label')}
                        onChange={this.props.setEmail}
                        value={this.props.email}
                        style={{backgroundColor: "white"}}
                        InputProps={{ disableUnderline: true, fullWidth: true }}
                        fullWidth
                        type={'email'}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label={t('password_label')}
                        onChange={this.props.setPassword}
                        value={this.props.password}
                        style={{backgroundColor: "white"}}
                        InputProps={{ disableUnderline: true, fullWidth: true }}
                        fullWidth
                        type={'password'}
                    />
                </Grid>
            </Grid>
            <br />
            <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    if (this.props.password === '' || this.props.email === '') {
                        alert(t('fill_required_fields_label'))
                        return
                    }
                    this.props.handleLoginClick()
                }}
            >
                {
                    this.props.isLoggingIn ?
                    t('logging_in') :
                    t('log_in_label')
                }
            </Button>
            {
                this.props.error &&
                <p style={{color: 'red'}}>{this.props.error}</p>
            }
        </div>
    );
  }
}

export default withTranslation()(Login)