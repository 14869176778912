import React, { Component } from 'react';
import LandingPage from './components/LandingPage/LandingPage';
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import RegisterPage from './components/Register/RegisterPage';
import { AppBar, Avatar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Tooltip, Typography } from '@mui/material';
import { LoginTwoTone, Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { connect } from 'react-redux';
import { getLoggedInUser, logIn, logOut, resetErr, sendContactMessage, setCurRoute, setScreenWidth, setUserCurrency, setUserLang } from './store/actions/authAction';
import Login from './components/Login/Login';
import { createBrowserHistory } from 'history';
import Wrapper from './components/Register/PaymentStatus';
import SetupSuccess from './components/Register/SetupSuccess';
import Account from './components/Account/Account';
// import Footer from './components/Footer/Footer';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { withTranslation } from 'react-i18next';
import i18next, { t } from 'i18next';
import { compose } from 'redux';
import UseTerms from './components/Footer/UseTerms';
import Footer from './components/Footer/Footer';
import PlanSelection from './components/LandingPage/PlanSelection';
import DTLandingPage from './components/LandingPage/DTLandingPage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import { withRouter } from './withRouter';
// import EnFlag from '../src/img/en-flag.jpeg';
// import ThFlag from '../src/img/th-flag.jpeg';
// import PtFlag from '../src/img/pt-flag.png';


const history = createBrowserHistory()
// const navigate = useNavigate();

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      _anchorEl: null,
      isModalOpen: false,
      email: '',
      password: '',
      drawerOpen: false,
      scrollPos: 0
    }
    this.languages = [
      {key: 'en', nativeName: 'English', flag: require('../src/img/en-flag.jpeg')},
      {key: 'th', nativeName: 'ไทย', flag: require('../src/img/th-flag.jpeg')},
      {key: 'pt', nativeName: 'Português', flag: require('../src/img/pt-flag.png')},
    ]
    // this.unlisten = null
  }

  setLoginModal = (value) => {
    this.setState({isModalOpen: value})
  }

  setAnchorEl = (val) => {
    this.setState({anchorEl: val})
  }

  _setAnchorEl = (val) => {
    this.setState({_anchorEl: val})
  }

  handleResize = () => {
    const width = window.innerWidth
    console.log('innerwidth: ', window.innerWidth)
    this.props.setScreenWidth(width)
  };

  updateScrollPosition = () => {
    this.setState({scrollPos: window.scrollY})
  }

  componentDidMount() {

    // if (this.props.curRoute === '/app')
    const userLang = i18next.language
    this.props.setUserLang(userLang)
    if (userLang === 'th') {
      this.props.setUserCurrency('thb')
    } else if (userLang === 'pt') {
      this.props.setUserCurrency('brl')
    }
    
    this.props.getLoggedInUser()
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.updateScrollPosition)
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoggingIn, error, userSubscriptionPlan, curRoute } = this.props

    if (prevProps.isLoggingIn !== isLoggingIn) {
        if (!isLoggingIn && error === null) {
            // this.props.history.push('/setup-status')
            this.setState({isModalOpen: false})
        }
    }

    // if (prevProps.curRoute !== curRoute) {
      // if (curRoute === '/') {
      //     this.props.history.push('/app')
      //     // this.setState({isModalOpen: false})
      // }
    // }

  }

  handleLoginClick = () => {
    const { email, password } = this.state
    const data = {
      email,
      password
    }
    this.props.logIn(data)
  }

  setEmail = e => {
    const email = e.target.value
    this.setState({email})
  }

  setPassword = e => {
    const password = e.target.value
    this.setState({password})
  }

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  _handleClick = (event) => {
    this._setAnchorEl(event.currentTarget);
  };

  _handleClose = () => {
    this._setAnchorEl(null);
  };

  handleLogout = () => {
    this.props.logOut()
  }

  openDrawer = () => {
    this.setState({drawerOpen: true})
  }

  openLoginModal = () => {
    this.props.resetErr()
    this.closeDrawer()
    this.setState({isModalOpen: true})
  }

  renderLangSwitcherMenu = () => {
    const { t } = this.props
    const curLang = i18next.language
    console.log('curLang: ', curLang)
    const open = Boolean(this.state._anchorEl);
    const langObj = this.languages.find(item => item.key === curLang.substring(0, 2))
    console.log('langObj: ', langObj)
    if (!langObj) return null
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <button onClick={this._handleClick} style={{background: 'black', color: 'white', fontWeight: "bold", fontSize: 16, alignSelf: 'flex-end'}}>
            <img src={langObj.flag} style={{height: 10, width: 15, marginRight: 10}} />
            { t('language_label') }
          </button>
        </div>
        <Menu
            anchorEl={this.state._anchorEl}
            id="account-menu"
            open={open}
            onClose={this._handleClose}
            onClick={this._handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {
              this.languages.filter(item => item.key !== curLang).map(item => {
                return (
                  <MenuItem onClick={() => this.handleLanguageChange(item)}>
                    <ListItemIcon>
                      <img src={item.flag} style={{height: 20, width: 25, marginRight: 10}} />
                    </ListItemIcon>
                    {item.nativeName}
                  </MenuItem>
                )
              })
            }
          </Menu>
      </div>
    )
  }

  renderLangSwitcher = () => {
    const curLang = i18next.language
    console.log('curLang: ', curLang)
    const open = Boolean(this.state._anchorEl);
    const langObj = this.languages.find(item => item.key === curLang.substring(0, 2))
    console.log('langObj: ', langObj)
    if (!langObj) return null
    return (
      <div>
        <button onClick={this._handleClick} style={{background: 'white', marginLeft: 10, padding: 10, color: 'dimgrey', fontSize: 16}}>
          <img src={langObj.flag} style={{height: 10, width: 15, marginRight: 10}} />
          {langObj.nativeName}
        </button>
        <Menu
            anchorEl={this.state._anchorEl}
            id="account-menu"
            open={open}
            onClose={this._handleClose}
            onClick={this._handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {
              this.languages.filter(item => item.key !== curLang).map(item => {
                return (
                  <MenuItem onClick={() => this.handleLanguageChange(item)}>
                    <ListItemIcon>
                      <img src={item.flag} style={{height: 20, width: 25, marginRight: 10}} />
                    </ListItemIcon>
                    {item.nativeName}
                  </MenuItem>
                )
              })
            }
          </Menu>
      </div>
    )
  }

  handleLanguageChange = (item) => {
    const lang = item.key
    i18next.changeLanguage(lang)
    this.props.setUserLang(lang)
    if (lang === 'th') {
      this.props.setUserCurrency('thb')
    }
    else if (lang === 'pt') {
      this.props.setUserCurrency('brl')
    }
    // this.forceUpdate()
  }
 
  renderAppBarContent = () => {
    const { screenWidth } = this.props
    const open = Boolean(this.state.anchorEl);
    if (this.props.loggedInUser.id) {
      return (
        <div style={{padding: 20}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <>
              <Link to='/app'>
                <img src={require('./img/logo.png')} style={{width:200, height: 60}} />
              </Link>
            </>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography> */}
              {
                screenWidth > 650 &&
                this.renderLangSwitcher()
              }
              <Tooltip title={t('account_settings_label')}>
                <IconButton
                  onClick={this.handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>{this.props.loggedInUser.firstName.charAt(0).toUpperCase()}</Avatar>
                </IconButton>
              </Tooltip>
            </div>
          </Box>
          <Menu
            anchorEl={this.state.anchorEl}
            id="account-menu"
            open={open}
            onClose={this.handleClose}
            onClick={this.handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              // onClick={this.handleMyAccountClick}
              component={Link}
              to="/app/user-account"
            >
              <Avatar /> {this.props.t('my_acc_label')}
            </MenuItem>
            {/* <MenuItem
              // onClick={this.handleMyAccountClick}
              component={Link}
              to="/subscriptions"
            >
              <Avatar /> Subscriptions
            </MenuItem> */}
            <Divider />
            <MenuItem onClick={this.handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {this.props.t('logout_label')}
            </MenuItem>
          </Menu>
        </div>
      )
    }
    else {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2}}>
          <>
            <Link to='/app'>
              <img src={require('./img/logo.png')} style={{width:200, height: 60}} />
            </Link>
          </>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {
              screenWidth < 650 ?
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {
                  // this.renderLangSwitcher()
                }
                <MenuItem onClick={this.openDrawer} >
                  <MenuIcon />
                </MenuItem>
              </div> :
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                
                <Button
                  onClick={this.openLoginModal}
                  sx={{color: 'white'}}
                  // component={Link}
                  // to="/login"
                >
                  {this.props.t('log_in_label')}
                </Button>
                {
                  (this.props.curRoute !== '/app/register' && this.props.curRoute !== '/app/plan-selection') &&
                  <Button
                    component={Link}
                    to="/app/plan-selection"
                    sx={{color: 'white'}}
                  >
                    {this.props.t('get_started_label')}
                  </Button>
                }
                {
                  this.renderLangSwitcher()
                }
              </div>
            }
          </div>
        </Box>
      )
    }
  }

  renderDrawerContent = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      // onClick={this.closeDrawer}
      onKeyDown={this.closeDrawer}
    >
      {
        this.renderLangSwitcherMenu()
      }
      <List>
          <ListItem onClick={this.openLoginModal} button>
            <ListItemIcon>
              <LoginTwoTone />
            </ListItemIcon>
            <ListItemText primary={this.props.t('log_in_label')} />
          </ListItem>
          {
            this.props.curRoute !== '/app/plan-selection' &&
            <ListItem component={Link} to='/app/plan-selection' button>
              <ListItemIcon>
                <AppRegistrationIcon />
              </ListItemIcon>
              <ListItemText primary={this.props.t('get_started_label')} />
            </ListItem>
          }
      </List>
    </Box>
  );
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.updateScrollPosition)
  }

  closeDrawer = () => {
    this.setState({drawerOpen: false})
  }
  
  render() {
    console.log('loggedinuser: ', this.props.loggedInUser)
    // console.log('screenWidth: ', this.props.screenWidth)
    console.log('lang: ', i18next.language)
    const { t, screenWidth } = this.props
    const btnSize = 50*screenWidth/400 > 80 ? 80 : 50*screenWidth/400
    return (
      <BrowserRouter>
        <div>
          <AppBar
            sx={{backgroundColor: 'black', height: 100}}
            // position={this.state.scrollPos > 100 ? 'fixed' : 'static'}
            position='fixed'
            variant='elevation'
          >
            {
              this.renderAppBarContent()
            }
          </AppBar>
          <div style={{marginTop: 100}}>
            <Routes history={history}>
              {/* <Link to={'/'} component={LandingPage} /> */}
              {/* <LandingPage /> */}
              <Route path="/" element={<DTLandingPage />} exact />
              <Route path="/app" exact element={<LandingPage />} />
              <Route path="/app/plan-selection" element={<PlanSelection />} />
              <Route path="/app/register" element={<RegisterPage setLoginModal={this.setLoginModal} />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/app/payment-status" element={<Wrapper />} />
              <Route path="/app/payment-success" element={<SetupSuccess />} />
              <Route path="/app/user-account" key={3} element={<Account />} />
              <Route path="/terms-of-use" element={<UseTerms />} />
              {/* <Route path="/subscriptions" element={<SubscriptionPlan />} /> */}
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>{t('nothing_here_label')}</p>
                  </main>
                }
              />
              {/* <Route path="/login-page" component={LoginPage} /> */}
              {/* <Route path="/meal-plans" component={MealPlans} /> */}
            </Routes>
          </div>
          <Footer setUserLang={this.props.setUserLang} setUserCurrency={this.props.setUserCurrency} userCurrency={this.props.userCurrency} resetErr={this.props.resetErr} error={this.props.error} isSendingContactMessage={this.props.isSendingContactMessage} sendContactMessage={this.props.sendContactMessage} />
          {
            this.props.userLang === 'pt' &&
            <a target="_blank" href='https://wa.me/5511968623553/?text=Quero%20participar%20do%20programa%20de%20treino%20personalizado'>
              <WhatsAppIcon
                sx={{color: 'limegreen', position: "fixed", bottom: '0.5%', right: '0%', width:btnSize, height: btnSize}}
              />
            </a>
          }
          <Modal
            open={this.state.isModalOpen}
            onClose={() => this.setState({isModalOpen: false, email: "", password: ''}, () => this.props.resetErr())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Login
                setEmail={this.setEmail}
                setPassword={this.setPassword}
                handleLoginClick={this.handleLoginClick}
                isLoggingIn={this.props.isLoggingIn}
                error={this.props.error}
                email={this.state.email}
                password={this.state.password}
              />
            </Box>
          </Modal>
          <Drawer
            anchor={'right'}
            open={this.state.drawerOpen}
            onClose={this.closeDrawer}
          >
            {this.renderDrawerContent()}
          </Drawer>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      loggedInUser: state.auth.loggedInUser,
      isLoggingIn: state.auth.isLoggingIn,
      userSubscriptionPlan: state.auth.userSubscriptionPlan,
      userCurrency: state.auth.userCurrency,
      curRoute: state.auth.curRoute,
      screenWidth: state.auth.screenWidth,
      userLang: state.auth.userLang,
      isSendingContactMessage: state.auth.isSendingContactMessage,
      error: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getLoggedInUser: () => dispatch(getLoggedInUser()),
      setCurRoute: (val) => dispatch(setCurRoute(val)),
      setUserLang: (val) => dispatch(setUserLang(val)),
      setUserCurrency: (val) => dispatch(setUserCurrency(val)),
      setScreenWidth: (val) => dispatch(setScreenWidth(val)),
      logIn: (data) => dispatch(logIn(data)),
      sendContactMessage: (data) => dispatch(sendContactMessage(data)),
      logOut: () => dispatch(logOut()),
      resetErr: () => dispatch(resetErr())
      
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid white',
  boxShadow: 24,
  // p: 4,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(App)