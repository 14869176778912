import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { CheckCircle, CheckCircleOutline, Email } from '@mui/icons-material';
import { Button, Grid, ListItemIcon, Menu, MenuItem, TextField } from '@mui/material';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userEmail: '',
            userMessage: '',
            emailRequiredErr: '',
            messageSent: false,
            // isCurrencyToolTipOpen: false,
            anchorEl: null,
            _anchorEl: null,
        }
    }

    handleChange = input => e => {
        this.setState({[input]: e.target.value, emailRequiredErr: input === 'userEmail' ? '' : this.state.emailRequiredErr, messageSent: false})
    }

    handleAppleStoreClick = () => {
        const url = 'https://apps.apple.com/th/app/dt-training-app/id1550242297';
        window.open(url, '_blank');
    }

    handleAndroidStoreClick = () => {
        const url = 'https://play.google.com/store/apps/details?id=com.dttrainingapp';
        window.open(url, '_blank');
    }

    handleChangeLang = (lang) => {
        i18next.changeLanguage(lang)
        this.props.setUserLang(lang)
        if (lang === 'th') {
            this.props.setUserCurrency('thb')
        }
        else if (lang === 'pt') {
            this.props.setUserCurrency('brl')
        }
    }

    handleClose = () => {
        this.setAnchorEl(null);
      };

      setAnchorEl = (val) => {
        this.setState({anchorEl: val})
      }

    renderFooterRightColumn = () => {
        const { t } = this.props
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                <h2 style={{color: 'white'}}>{t('get_the_app')}</h2>
                <img src={require('../../img/app-icon.jpg')} style={{width: 70, height: 70, borderRadius: 10, marginTop: 10}} />
                <div style={{display: 'flex', padding: 10, flexDirection: 'row', alignItems: 'center', }}>
                    <a target="_blank" href='https://apps.apple.com/th/app/dt-training-app/id1550242297'>
                        <img src={require('../../img/apple-store.jpg')} style={{width: 90, height: 40, borderRadius: 15, marginRight: 20}} />
                    </a>
                    <a target="_blank" href='https://play.google.com/store/apps/details?id=com.dttrainingapp'>
                        <img src={require('../../img/android-store.png')} style={{width: 90, height: 40, borderRadius: 15,}} />
                    </a>
                </div>
                <div style={{display: 'flex', padding: 10, flexDirection: 'row', alignItems: 'center'}}>
                    <p style={{marginRight: 10}}>{t('contact_label')}</p>
                    <a target="_blank" href='https://www.facebook.com/DTTrainingApp'>
                        <FacebookIcon sx={{marginRight: 1, color: 'black'}} />
                    </a>
                    <a target="_blank" href='https://www.instagram.com/dt.training.app'>
                        <InstagramIcon sx={{marginRight: 1, color: 'black'}} />
                    </a>
                    <a target="_blank" href='mailto:support@diegotrainer.com'>
                        <Email sx={{color: 'black'}} />
                    </a>
                </div>
            </div>
        )
    }

    handleSendMessage = () => {
        const { userName, userEmail, userMessage } = this.state
        if (userName === '' || userEmail === '' || userMessage === '') {
            alert('All fields are required.')
            return
        }
        const data = {
            userName,
            userEmail,
            userMessage
        }
        this.props.sendContactMessage(data)
    }

    _handleClose = () => {
        this._setAnchorEl(null);
      };

    componentDidUpdate(prevProps) {
        const { isSendingContactMessage, error } = this.props
        if (prevProps.isSendingContactMessage !== isSendingContactMessage) {
            console.log('isSendingContactMessage: ', isSendingContactMessage)
            if (!isSendingContactMessage && error === null) {
                // alert('Message sent successfully!')
                this.setState({userName: '', userEmail: '', userMessage: '', messageSent: true})
            } else if (!isSendingContactMessage && error !== null) {
                this.setState({emailRequiredErr: error})
                this.props.resetErr()
            }
        }
    }

    _handleClick = (event) => {
        this._setAnchorEl(event.currentTarget);
      };

      _setAnchorEl = (val) => {
        this.setState({_anchorEl: val})
      }
    
    render() {
        const { t } = this.props
        const {emailRequiredErr} = this.state
        const lang = i18next.language.substring(0, 2)
        const open = Boolean(this.state._anchorEl);
        // console.log('lang2: ', window.location.pathname)
        
        return (
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor:'grey', alignItems:'center', justifyContent:'center'}}>
                {/* <div>
                    <h1>Get in touch</h1>
                </div> */}
                <div style={{display: 'flex', flexWrap: 'wrap', width:'100%', marginBottom: 10}}>
                
                <Grid container spacing={1} alignItems='center' justifyContent='space-around' >
                    <Grid item md={5} xs={10}>
                        <h2 style={{color: 'white'}}>{t('contact_label')}</h2>
                        <TextField
                            // label='Name'
                            placeholder={t('your_name')}
                            hiddenLabel
                            variant="filled"
                            onChange={this.handleChange('userName')}
                            value={this.state.userName}
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                            disabled={this.props.isSendingContactMessage}
                            size="small"
                            inputProps={{maxLength: 50}}
                            sx={{fontSize: 15, backgroundColor: 'white', marginBottom: 1}}
                        />
                        <TextField
                            placeholder={t('your_email')}
                            error={emailRequiredErr !== ''}
                            helperText={emailRequiredErr === '' ? null : emailRequiredErr}
                            hiddenLabel
                            type={'email'}
                            variant="filled"
                            onChange={this.handleChange('userEmail')}
                            value={this.state.userEmail}
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                            disabled={this.props.isSendingContactMessage}
                            size="small"
                            inputProps={{maxLength: 70}}
                            sx={{fontSize: 15, backgroundColor: 'white', marginBottom: 1}}
                        />
                        <TextField
                            //label='Message'
                            disabled={this.props.isSendingContactMessage}
                            placeholder={t('your_message')}
                            hiddenLabel
                            variant="filled"
                            onChange={this.handleChange('userMessage')}
                            value={this.state.userMessage}
                            InputProps={{ disableUnderline: true }}
                            multiline
                            fullWidth
                            inputProps={{maxLength: 200}}
                            size="small"
                            minRows={2}
                            maxRows={2}
                            sx={{fontSize: 15, backgroundColor: 'white', marginBottom: 1}}
                        />
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Button
                                variant={this.props.isSendingContactMessage ? 'text' : 'contained'}
                                color='secondary'
                                // sx={{alignSelf:'center'}}
                                onClick={this.handleSendMessage}
                                disabled={this.props.isSendingContactMessage}
                                // component={Link}
                                // to="/register"
                                // color="danger"
                                // size="lg"
                                // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                                // target="_blank"
                                // rel="noopener noreferrer"
                            >
                                {/* <i className="fas fa-play" /> */}
                                {this.props.isSendingContactMessage ? t('sending_btn') : t('send_btn')}
                            </Button>
                            {
                                this.state.messageSent &&
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CheckCircle style={{color: '#7fff00', marginLeft: 10, marginRight: 5}} />
                                    <span style={{fontWeight: 'bold', color: 'black'}}>{t('success_label')}</span>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} >
                    {
                        this.renderFooterRightColumn()
                    }
                    </Grid>
                </Grid>
                    
                </div>
                <div style={{display: "flex", flexDirection: 'rows', marginBottom: 5, marginLeft: 5}}>
                    <div style={{marginRight: 10}}>
                        <span onClick={() => lang === 'en' ? null : this.handleChangeLang('en')} style={{color: lang === 'en' ? 'black' : 'white', textDecorationLine: lang === 'en' ? 'normal' : 'underline'}}>English</span>
                        <> | </>
                        <span onClick={() => lang === 'th' ? null : this.handleChangeLang('th')} style={{color: lang === 'th' ? 'black' : 'white', textDecorationLine: lang === 'th' ? 'normal' : 'underline'}}>ไทย</span>
                        <> | </>
                        <span onClick={() => lang === 'pt' ? null : this.handleChangeLang('pt')} style={{color: lang === 'pt' ? 'black' : 'white', textDecorationLine: lang === 'pt' ? 'normal' : 'underline'}}>Português</span>
                        <> | </>
                        {
                            window.location.pathname === '/terms-of-use' ?
                            <span style={{color: 'black'}}>{t('terms_of_use_label')}</span> :
                            <Link to={'/terms-of-use'}>
                                <span style={{color: 'white', textDecorationLine: 'underline'}}>{t('terms_of_use_label')}</span>
                            </Link>
                        }
                    </div>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <button
                                onClick={this._handleClick}
                                style={{background: 'black', color: 'white', fontWeight: "bold", fontSize: 16, alignSelf: 'flex-end'}}
                            >
                                {
                                    this.props.userCurrency.toUpperCase()
                                }
                            </button>
                        </div>
                        <Menu
                            anchorEl={this.state._anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={this._handleClose}
                            onClick={this._handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: -13,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        // top: 0,
                                        bottom: -9,
                                        right: 16,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem
                                onClick={() => this.props.setUserCurrency('usd')}
                            >
                                <ListItemIcon>
                                    <span>$</span>
                                </ListItemIcon>
                                USD
                            </MenuItem>
                            <MenuItem
                               onClick={() => this.props.setUserCurrency('brl')}
                            >
                                <ListItemIcon>
                                    <span>R$</span>
                                </ListItemIcon>
                                BRL
                            </MenuItem>
                            <MenuItem
                                onClick={() => this.props.setUserCurrency('thb')}
                            >
                                <ListItemIcon>
                                    <span>฿</span>
                                </ListItemIcon>
                                THB
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div style={{backgroundColor: 'black', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}} >
                    <p style={{color:'white', marginTop: 10, marginLeft: 10, marginRight: 10, textAlign: 'center'}}>{t('footer_disclaimer')}</p>
                    <p style={{color:'white', alignSelf: 'center', textAlign: "center"}}>© 2022 DiegoTrainer.com - {t('copyright_label')}</p>
                </div>
            </div>
        )
    }
}
  
export default withTranslation()(Footer)