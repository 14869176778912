import { t } from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import { compose } from 'redux';

class SetupSuccess extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stripeError: null,
            // redirect: false
        }
    }

    componentDidMount() {
        // const { userSubscriptionPlan } = this.props
        // if (userSubscriptionPlan.status !== 'Active') {
        //     this.setState({redirect: true})
        // }
    }

    render() {
        const { t } = this.props
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
                <h1>{t('youre_set_label')}</h1>
                    
                <h2>{t('next_steps_label')}</h2>
                <p>{t('app_steps_label_one')}</p>
                <p>{t('app_steps_label_two')}</p>
                <p>{t('app_steps_label_three')}</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 50}}>
                        <img alt='ios-app' src={require('../../img/apple-store.jpg')} style={{width:200, height: 60}} />
                        <img alt='' src={require('../../img/ios_qr.png')} style={{width:300, height: 300}} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <img alt='android-app' src={require('../../img/android-store.png')} style={{width:200, height: 60}} />
                        <img alt='' src={require('../../img/android_qr.png')} style={{width:300, height: 300 }} />
                    </div>
                </div>
                {/* {
                    this.state.redirect &&
                    <Navigate to='/register' replace={true} />
                } */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSubscriptionPlan: state.auth.userSubscriptionPlan,
    }
}
  
export default compose(withTranslation(), connect(mapStateToProps))(SetupSuccess)