import React, { Component } from 'react';
import { Navigate, useNavigate} from "react-router-dom";
import { Button, Card, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurRoute, setPackageSelect, setRedirect } from '../../store/actions/authAction';
import Background from '../../img/header-img.jpg';
// import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Slider from 'react-slick';
import { ContactSupport, FitnessCenter, PhotoCamera, ShowChart, Star } from '@mui/icons-material';
import PlanSelectionSection from './PlanSelectionSection';
import { t } from 'i18next';
//import { withRouter } from '../../withRouter';
// import { withRouter } from '../../withRouter';
//import { withRouter } from "react-router";
// import i18next from 'i18next';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};

const WithRouter = (Component) => {
  const Wrapper = (props) => {
    
    const navigate = useNavigate();
    // const location = useLocation();
    
    return (
      <Component
        navigate={navigate}
        // location={location}
        {...props}
      />
    );
  }
  return Wrapper;
};

class LandingPage extends Component {
  constructor(props) {
    super(props)
    const { t } = props
    this.state = {
      // redirect: false,
      accRedirect: false
    }
    this.cards = [
      {key: 1, title: t('workout_tracking_label'), description: t('track_description'), icon: <FitnessCenter sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 2, title: t('performance_monitoring_label'), description: t('performance_description'), icon: <ShowChart sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 3, title: t('upload_stats_label'), description: t('upload_description'), icon: <PhotoCamera sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 4, title: t('24h_support_label'), description: t('support_description'), icon: <ContactSupport sx={{ fontSize: 100, color: 'dodgerblue' }} />},
    ]
    this.priceTableArr = [
      {key: 1, numMonths: 1, title: '1 Month', price: 800, bgColor: 'silver', icon: <FitnessCenter sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 2, numMonths: 3, title: '3 Months', price: 2040, bgColor: 'silver', icon: <ShowChart sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 3, numMonths: 6, title: '6 Months', price: 3840, bgColor: 'silver', icon: <PhotoCamera sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 4, numMonths: 12, title: '1 Year', price: 7200, bgColor: 'silver', icon: <ContactSupport sx={{ fontSize: 100, color: 'dodgerblue' }} />},
    ]
    this.programIncludes = [
      {key: 1, value: 'Custom Workout Plan'},
      {key: 2, value: 'Nutrition Follow-Up and Planning'},
      {key: 3, value: 'Monthly Physical Assessment + Body Tracker'},
      {key: 4, value: 'On-Going Performance Follow-Up'},
      {key: 5, value: '24/7 Priority Support'},
      {key: 6, value: 'Exclusive Access to DT Training App'},
      {key: 7, value: 'Built-In Tracking Capability'},
    ]
    this.programDescription = '1-on-1 Personal Training with Diego Trainer - Online & Powered by DT Training App'
  }

  // handleJoinNowBtn = () => {
  //   window.location.href = "/insert/your/path/here"
  // }

  componentDidMount() {
    // const { userSubscriptionPlan } = this.props
    console.log('PROPS: ', this.props)

    this.props.setCurRoute('/app')
    // if (!userSubscriptionPlan.id) {
    //   this.setState({redirect: true})
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoggingIn, userSubscriptionPlan, error } = this.props
    if (prevProps.isLoggingIn !== isLoggingIn) {
      if (!isLoggingIn && error === null) {
          if (!userSubscriptionPlan.id) {
            // this.setState({redirect: true}, () => this.setState({redirect: false}))
            this.props.setRedirect(true)
          }
          // else {
          //   this.setState({accRedirect: true})
          // }
      }
    }
  }

  renderAboutTheCoach = () => {
    const { t, screenWidth } = this.props
    const picWidth = 150*screenWidth/650 < 180 ? 180 : 150*screenWidth/650 > 250 ? 250 : 150*screenWidth/650
    if (screenWidth < 800) {
      return (
        <div style={{display: 'flex', backgroundColor: 'silver', flexDirection: 'column', alignItems: 'center', justifyContent:'center', paddingBottom: 10}}>
          <h2 style={{color: 'black', textAlign: 'center', fontWeight: 'bold'}}>{t('about_coach_title')}</h2>
          <div style={{padding: 10}}>
              <img src={require('../../img/coach_profile.jpg')} style={{height: picWidth, width: picWidth, borderRadius: picWidth/2, float:'left', marginRight: 10}} id="image-section"  />
              <h3 style={{alignSelf:'center', textAlign: 'left', lineHeight: 1.5}}>{t('about_coach_desc')}</h3>
          </div>
        </div>
      )
    }
    return (
      <div style={{display: 'flex', backgroundColor: 'silver', flexDirection: 'column', alignItems: 'center', justifyContent:'center', paddingBottom: 10}}>
        <h2 style={{color: 'black', textAlign: 'center', fontWeight: 'bold'}}>{t('about_coach_title')}</h2>
        <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
          <div style={{width: .3*screenWidth-10,  display: "flex", alignItems: 'center', justifyContent: 'center'}}>
            <img src={require('../../img/coach_profile.jpg')} style={{height: picWidth, width: picWidth, borderRadius: picWidth/2}} id="image-section"  />
          </div>
          <div style={{width: .7*screenWidth-10, alignItems: 'center', justifyContent: 'flex-start'}}>
            <h3 style={{alignSelf:'center', textAlign:'center', lineHeight: 1.5}}>{t('about_coach_desc')}</h3>
          </div>
        </div>
      </div>
    )
  }


  renderAppScreenshots = () => {
    const { t } = this.props
    return (
      <div style={{display: 'flex', backgroundColor: 'black', flexDirection: 'column', alignItems: 'center', justifyContent:'center', paddingBottom: 10}}>
        <h2 style={{color: 'white', textAlign: 'center'}}>{t('app_access_title')}</h2>
        <div style={{marginRight: 10, marginLeft: 10}}>
          <img src={require('../../img/app_screens.png')} style={{height: 'auto', width: '100%'}} id="image-section"  />
        </div>
      </div>
    )
  }

  renderSlider = () => {
    const { t } = this.props
    const userReviews = [
      {
        key: 1,
        rating: 5,
        review: t('review_one'),
        from: t('online_clients_tag'),
        name: 'Eric Clinton',
        // thumbPicture: "https://images.pexels.com/photos/3585047/pexels-photo-3585047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        thumbPicture: require('../../img/eric_clinton.jpg')
      },
      {
        key: 2,
        rating: 5,
        review: t('review_two'),
        from: t('online_clients_tag'),
        name: 'Jessica Sofia',
        // thumbPicture: "https://bestprofilepictures.com/wp-content/uploads/2020/07/Cool-Profile-Picture-For-Instagram-1003x1024.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        thumbPicture: require('../../img/jessica_sofia.jpg')
      },
      {
        key: 3,
        rating: 5,
        review: t('review_three'),
        from: t('online_clients_tag'),
        name: 'Fabio Martin',
        // thumbPicture: "https://images.pexels.com/photos/3585047/pexels-photo-3585047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        thumbPicture: require('../../img/fabio_martin.jpg')
      },
      {
        key: 4,
        rating: 5,
        review: t('review_four'),
        from: t('online_clients_tag'),
        name: 'Lara Murieti',
        // thumbPicture: "https://images.pexels.com/photos/3585047/pexels-photo-3585047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        thumbPicture: require('../../img/lara_murieti.jpg')
      }
    ]
    return (
      <div style={{backgroundColor: 'gainsboro', padding: 10}}>
        <h1 style={{textAlign: 'center'}}>{t('client_reviews_title')}</h1>
        <Slider
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          swipeToSlide={true}
          adaptiveHeight={false}
          autoplay={true}
          responsive={[
            {
              breakpoint: 1324,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                // adaptiveHeight: false
                // infinite: true,
                // dots: true
              }
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                // adaptiveHeight: false
                // initialSlide: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true
                // initialSlide: 2
              }
            },
            // {
            //   breakpoint: 480,
            //   settings: {
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     adaptiveHeight: true
            //   }
            // }
          ]}
          // centerPadding='100px'
          // autoplay={true}
          arrows={true}
        >
          {
            userReviews.map(item => {
              return (
                <div key={item.key.toString()}>
                  <div style={{display: 'flex', flexDirection: 'column', padding: 10, backgroundColor: 'white', marginRight: 20, marginBottom: 5, borderRadius: 10}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Star sx={{color: 'gold'}} />
                      <Star sx={{color: 'gold'}} />
                      <Star sx={{color: 'gold'}} />
                      <Star sx={{color: 'gold'}} />
                      <Star sx={{color: 'gold', marginRight: 1}} />
                      <span style={{color:'grey'}}>{t('avr_review_val')}</span>
                    </div>
                    <span style={{backgroundColor: 'white'}}>{item.review}</span>
                  </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <img src={item.thumbPicture} alt='' style={{width: 30, height: 30, borderRadius: 15, marginRight: 5}} />
                      <span style={{color: 'black'}}>{item.name}. <span style={{color: 'grey'}}>{item.from}</span></span>
                    </div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    )
  }

  renderFeatures = () => {
    const { t } = this.props
    const _cards = [
      {key: 1, title: t('workout_tracking_label'), description: t('track_description'), icon: <FitnessCenter sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 2, title: t('performance_monitoring_label'), description: t('performance_description'), icon: <ShowChart sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 3, title: t('upload_stats_label'), description: t('upload_description'), icon: <PhotoCamera sx={{ fontSize: 100, color: 'dodgerblue' }} />},
      {key: 4, title: t('24h_support_label'), description: t('support_description'), icon: <ContactSupport sx={{ fontSize: 100, color: 'dodgerblue' }} />},
    ]
    return (
      <div style={{backgroundColor:'dimgrey', paddingBottom: 10}}>
        <h2 style={{color: 'white', textAlign: 'center', alignSelf: 'center'}}>{t('features_title')}</h2>
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          // wrap="nowrap" // --> add this line to disable wrap
          // // sx={{ overflow: "auto" }} // --> add scrollbar when the content inside is overflowed
          spacing={2}
        >
          {
            _cards.map((card) => (
              <Grid
                // style={{alignSelf:'center'}}
                item lg={3} md={4} sm={6}
                xs='auto'
                key={card.key.toString()}
              >
                <Card
                  // xs='auto'
                  sx={{
                    width: 250,
                    height: 250,
                    margin: 1,
                    padding: 1,
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // alignSelf: 'center',
                  }}
                >
                  {card.icon}
                  <span style={{fontSize: 25, alignSelf:'center', textAlign:'center', fontWeight:'bold'}}>{card.title}</span>
                  <span style={{alignSelf:'center', textAlign:'center', lineHeight: 1.5}}>{card.description}</span>
                </Card>
              </Grid>
            ))
          }
        </Grid>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom:10}}>
          <Button
            variant='contained'
            // sx={{alignSelf:'center'}}
            // onClick={this.handleJoinNowBtn}
            component={Link}
            to="/app/plan-selection"
            // color="danger"
            // size="lg"
            // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            {/* <i className="fas fa-play" /> */}
            {t('get_started_btn')}
          </Button>
        </div>
      </div>
    )
  }

  // renderPriceTable = () => {
  //   const { t } = this.props
  //   return (
  //     <div style={{backgroundColor:'black', paddingBottom: 10}}>
  //       <h2 style={{color: 'white', textAlign: 'center', alignSelf: 'center'}}>Start Today!</h2>
  //       <h3 style={{alignSelf:'center', textAlign:'center', color: 'white'}}>{this.programDescription}</h3>
  //       <Grid
  //         container
  //         justifyContent={'center'}
  //         alignItems={'center'}
  //         // wrap="nowrap" // --> add this line to disable wrap
  //         // // sx={{ overflow: "auto" }} // --> add scrollbar when the content inside is overflowed
  //         spacing={2}
  //       >
  //         {
  //           this.priceTableArr.map((card) => (
  //             <Grid
  //               key={card.key.toString()}
  //               // style={{alignSelf:'center'}}
  //               item lg={3} md={4} sm={6}
  //               xs='auto'
  //             >
  //               <Card
  //                 // xs='auto'
  //                 sx={{
  //                   width: 250,
  //                   backgroundColor: card.bgColor,
  //                   // height: 250,
  //                   margin: 1,
  //                   padding: 1,
  //                   display: 'flex',
  //                   flexDirection:'column',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   // alignSelf: 'center',
  //                 }}
  //               >
  //                 {/* {card.icon} */}
  //                 <span style={{fontSize: 25, alignSelf:'center', textAlign:'center', fontWeight:'bold'}}>{card.title}</span>
  //                 {/* <span style={{alignSelf:'center', textAlign:'center'}}>{this.programDescription}</span> */}
  //                 <br />
  //                 <div style={{marginRight: 10, marginLeft: 10}}>
  //                   {
  //                     this.programIncludes.map(item => {
  //                       return (
  //                         <div key={item.key.toString()} style={{display: 'flex', alignSelf: "flex-start", justifyContent: 'flex-start', marginBottom: 5}}>
  //                           <CheckRounded color='primary' sx={{color: 'green', marginRight: .5}} />
  //                           <span> {item.value}</span>
  //                         </div>
  //                       )
  //                     })
  //                   }
  //                 </div>
  //                 <span style={{fontWeight: "bold", marginTop: 20}}>{card.price.toLocaleString()} THB</span>
  //                 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom:10}}>
  //                   <Button
  //                     variant='contained'
  //                     // sx={{alignSelf:'center'}}
  //                     onClick={() => {
  //                       this.props.setPackageSelect(card)
  //                     }} // this.props.navigate('/register', { selectedOption: card})}
  //                     component={Link}
  //                     to="/register"
  //                     // color="danger"
  //                     // size="lg"
  //                     // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
  //                     // target="_blank"
  //                     // rel="noopener noreferrer"
  //                   >
  //                     {/* <i className="fas fa-play" /> */}
  //                     BUY
  //                   </Button>
  //                 </div>
  //               </Card>
  //             </Grid>
  //           ))
  //         }
  //       </Grid>
  //     </div>
  //   )
  // }
  
  render() {
    const {t, screenWidth} = this.props
    console.log('props: ', this.props )
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div
          style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: screenWidth > 650 ? 'flex-start' : "center", padding: 20, height: 500}}
        >
          <h1 style={{textAlign: screenWidth > 650 ? 'left' : 'center'}}>{t('headline_one_one')}<br />{t('headline_one_two')}</h1>
          <h3 style={{textAlign: 'center', color: 'white', padding: 10, borderRadius: 10, opacity: .7, backgroundColor: 'black', lineHeight: 1.5, border: '2'}}>
            {t('headline_two')}
          </h3>
          <br />
          <Button
            variant='contained'
            // onClick={this.handleJoinNowBtn}
            component={Link}
            // to="/register"
            to="/app/plan-selection"
            // color="danger"
            // size="lg"
            // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            {/* <i className="fas fa-play" /> */}
            {t('get_started_now')}
          </Button>
          {
            this.props.redirect &&
            <Navigate to='/app/register' replace={true} />
          }
          {/* {
            this.state.accRedirect &&
            <Navigate to='/user-account' replace={true} />
          } */}
        </div>
        {
          this.renderAboutTheCoach()
        }
        {
          this.renderAppScreenshots()
        }
        {
          this.renderFeatures()
        }
        {
          this.renderSlider()
        }
        <PlanSelectionSection
          // programIncludes={this.programIncludes}
          // programDescription={this.programDescription}
          // setPackageSelect={this.props.setPackageSelect}
          // priceTableArr={this.priceTableArr}
          // t={t}
          // {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      loggedInUser: state.auth.loggedInUser,
      isLoggingIn: state.auth.isLoggingIn,
      userSubscriptionPlan: state.auth.userSubscriptionPlan,
      error: state.auth.error,
      redirect: state.auth.redirect,
      screenWidth: state.auth.screenWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurRoute: (val) => dispatch(setCurRoute(val)),
    setPackageSelect: (data) => dispatch(setPackageSelect(data)),
    setRedirect: (val) => dispatch(setRedirect(val)),
  }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(LandingPage)
