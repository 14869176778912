const initState = {
    loggedInUser: {},
    userSubscriptionPlan: {},
    isRegistering: false,
    isGettingSecret: false,
    token: null,
    curRoute: '/',
    isLoggingIn: false,
    isAutoLoggingIn: false,
    isSendingContactMessage: false,
    isSubscribingUser: false,
    redirect: false,
    screenWidth: window.innerWidth,
    userLang: 'en',
    userCurrency: 'usd',
    packageSelected: {},
    error: null
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case 'REGISTER_REQUEST':
                return {
                    ...state,
                    error: null,
                    isRegistering: true
                }
        case 'REGISTER_SUCCESS':
            console.log('res5: ', action.payload)
                return {
                    ...state,
                    isRegistering: false,
                    loggedInUser: action.payload.user,
                    token: action.payload.token,
                    // isLoggedIn: true
                }
        case 'REGISTER_ERROR':
                return {
                    ...state,
                    isRegistering: false,
                    error: action.payload.error
                }
        case 'LOG_IN_SUCCESS':
            console.log(action.payload)
            const { user, userSubscriptionPlans, token } = action.payload
            return {
                ...state,
                loggedInUser: user,
                userSubscriptionPlan: userSubscriptionPlans.length > 0 ? userSubscriptionPlans[0] : {},
                token: token,
                isLoggingIn: false,
                isAutoLoggingIn: false
                // isLoggedIn: true,
            }
        case 'LOG_IN_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isLoggingIn: true
            }
        case 'AUTO_LOG_IN_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isAutoLoggingIn: true
            }
        case 'LOG_IN_ERROR':
            console.log(action.payload)
            return {
                ...state,
                isLoggingIn: false,
                isAutoLoggingIn: false,
                error: action.payload
            }
        case 'GET_SECRET_SUCCESS':
            console.log(action.payload)
            return {
                ...state,
                loggedInUser: { ...state.loggedInUser, stripeClientSecret: action.payload.stripeClientSecret },
                isGettingSecret: false,
            }
        case 'GET_SECRET_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isGettingSecret: true
            }
        case 'GET_SECRET_ERROR':
            console.log(action.payload)
            return {
                ...state,
                isGettingSecret: false,
                error: action.payload.error
            }
        case 'CREATE_TRIAL_SUBSCRIPTION_SUCCESS':
            console.log('subcr: ', action.payload)
            return {
                ...state,
                userSubscriptionPlan: action.payload,
                isCreatingSubscription: false,
            }
        case 'CREATE_TRIAL_SUBSCRIPTION_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isCreatingSubscription: true
            }
        case 'CREATE_TRIAL_SUBSCRIPTION_ERROR':
            console.log(action.payload)
            return {
                ...state,
                isCreatingSubscription: false,
                error: action.payload
            }
        case 'SET_CUR_ROUTE':
            console.log(action.payload)
            return {
                ...state,
                curRoute: action.payload
            }
        case 'LOG_OUT':
            console.log(action.payload)
            return {
                ...state,
                loggedInUser: {},
                userSubscriptionPlan: {},
                token: null
            }
        case 'RESET_ERR':
            return {
                ...state,
                error: null
            }
        case 'SET_SCREEN_WIDTH':
            return {
                ...state,
                screenWidth: action.payload
            };
        case 'SET_USER_LANG':
            return {
                ...state,
                userLang: action.payload
            };
        case 'SEND_CONTACT_MESSAGE_SUCCESS':
            console.log(action.payload)
            return {
                ...state,
                isSendingContactMessage: false,
            }
        case 'SEND_CONTACT_MESSAGE_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isSendingContactMessage: true
            }
        case 'SEND_CONTACT_MESSAGE_ERROR':
            console.log(action.payload)
            return {
                ...state,
                isSendingContactMessage: false,
                error: action.payload
            }
        case 'SET_PACKAGE_SELECTED':
            console.log('fired!', action.payload)
            return {
                ...state,
                packageSelected: action.payload
            }
        case 'SUBSCRIBE_USER_SUCCESS':
            console.log('userSubscriptionPlan: ', action.payload)
            return {
                ...state,
                isSubscribingUser: false,
                userSubscriptionPlan: action.payload
            }
        case 'SUBSCRIBE_USER_REQUEST':
            console.log(action.payload)
            return {
                ...state,
                isSubscribingUser: true
            }
        case 'SUBSCRIBE_USER_ERROR':
            console.log(action.payload)
            return {
                ...state,
                isSubscribingUser: false,
                error: action.payload
            }
        case 'SET_REDIRECT':
            console.log(action.payload)
            return {
                ...state,
                redirect: action.payload
            }
        case 'SET_USER_CURRENCY':
            console.log(action.payload)
            return {
                ...state,
                userCurrency: action.payload
            }
        default:
            return state
    }
}

export default auth