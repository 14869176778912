import { rawUrl } from "../../helpers/constants";

const url = rawUrl + 'api/user/websitepayment/';

export const register = (data) => {
    console.log('data: ', data)
    // const { packageKey } = data

    //console.log(user)
    return (dispatch) => {
        dispatch(registerRequest());
        fetch(url + 'websiteregister', {
            method: 'POST',
            body: JSON.stringify(data),
            origin: "*",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        //.then(res => console.log(res.headers))

        .then(res => res.json())
        //.then(res => console.log(res))
        .then(res => {
            console.log('res: ', res)
            if (res.error) {
                //console.log(res)
                throw(res);
            }
            console.log(res)
            // AsyncStorage.setItem('token', res.token);
            localStorage.setItem('token', res.token);
            dispatch(registerSuccess(res));
        })
        .catch(error => {
            console.log('error:  ' + error)
            dispatch(registerError(error));
        })
    }
};

export const registerRequest = () => {
    return {
        type: 'REGISTER_REQUEST'
    }
};

export const registerSuccess = (data) => {
    return {
        type: 'REGISTER_SUCCESS',
        payload: data
    }
};

export const registerError = (error) => {
    return {
        type: 'REGISTER_ERROR',
        payload: error
    }
};

//persist a login when your user revisits the page
export const getLoggedInUser = () => {
    console.log('got to getLoggedInUser')
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        console.log(token)
        if (!token) {
            return null
        }
        dispatch(autoLogInRequest());
        return fetch(url + 'getloggeduser', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
        })
        .then(res => res.json())
        .then(res => {
            console.log('got to getLoggedInUser2', res)
            if (res.error) {
                localStorage.removeItem('token')
                dispatch(logInError());
                return
            }
            dispatch(logInSuccess(res))
        })
    }
};

export const setCurRoute = (value) => {
    return {
        type: 'SET_CUR_ROUTE',
        payload: value
    }
};

export const logIn = (data) => {
    return (dispatch) => {
        dispatch(logInRequest());
        fetch(url + 'websitelogin', {
            method: 'POST',
            body: JSON.stringify(data),
            origin: "*",
            headers: {
                'Content-Type': 'application/json'
            }
        })

        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (res.error) {
                console.log(res)
                throw(res.error);
            }
            console.log(res)
            localStorage.setItem('token', res.token);
            dispatch(logInSuccess(res));
        })
        .catch(error => {
            console.log('error ', error)
            dispatch(logInError(error));
        })
    }
};

export const logInRequest = () => {
    return {
        type: 'LOG_IN_REQUEST'
    }
};

export const logInSuccess = (data) => {
    return {
        type: 'LOG_IN_SUCCESS',
        payload: data
    }
};

export const autoLogInRequest = () => {
    return {
        type: 'AUTO_LOG_IN_REQUEST'
    }
};

export const logInError = (error) => {
    return {
        type: 'LOG_IN_ERROR',
        payload: error
    }
};

export const getSecret = (data) => {
    const { packageKey, userId, currency } = data
    console.log('data22: ', data)
    //console.log(user)
    return (dispatch) => {
        dispatch(getSecretRequest());
        fetch(url + 'getsecret/' + userId + '/' + packageKey + '/' + currency, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log('res: ', res)
            if (res.error) {
                //console.log(res)
                throw(res);
            }
            console.log(res)
            dispatch(getSecretSuccess(res));
        })
        .catch(error => {
            console.log('error:  ' + error)
            dispatch(getSecretError(error));
        })
    }
};

export const getSecretRequest = () => {
    return {
        type: 'GET_SECRET_REQUEST'
    }
};

export const getSecretSuccess = (data) => {
    return {
        type: 'GET_SECRET_SUCCESS',
        payload: data
    }
};

export const getSecretError = (data) => {
    return {
        type: 'GET_SECRET_ERROR',
        payload: data
    }
};

export const logOut = () => {
    localStorage.removeItem('token')
    return {
        type: 'LOG_OUT'
    }
};

// CREATE TRIAL SUBSCRIPTION
export const createTrialSubscription = (userId) => {
    return (dispatch) => {
        dispatch(createTrialSubscriptionRequest());
        fetch(url + 'createtrialsubscription/' + userId, {
            method: 'GET',
            //body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })

        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (res.error) {
                console.log(res)
                throw(res.error);
            }
            console.log(res)
            dispatch(createTrialSubscriptionSuccess(res));
        })
        .catch(error => {
            console.log('error ', error)
            dispatch(createTrialSubscriptionError(error));
        })
    }
};

export const createTrialSubscriptionRequest = () => {
    return {
        type: 'CREATE_TRIAL_SUBSCRIPTION_REQUEST'
    }
};

export const createTrialSubscriptionSuccess = (data) => {
    return {
        type: 'CREATE_TRIAL_SUBSCRIPTION_SUCCESS',
        payload: data
    }
};

export const createTrialSubscriptionError = (error) => {
    return {
        type: 'CREATE_TRIAL_SUBSCRIPTION_ERROR',
        payload: error
    }
};

export const resetErr = () => {
    return {
        type: 'RESET_ERR'
    }
};

export const setScreenWidth = (data) => {
    return {
        type: 'SET_SCREEN_WIDTH',
        payload: data
    }
}

export const setUserLang = (data) => {
    return {
        type: 'SET_USER_LANG',
        payload: data
    }
}

export const sendContactMessage = (data) => {
    return (dispatch) => {
        dispatch(sendContactMessageRequest());
        fetch(url + 'sendcontactmessage', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })

        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (res.error) {
                console.log(res)
                throw(res.error);
            }
            console.log('res: ', res)
            dispatch(sendContactMessageSuccess(res));
        })
        .catch(error => {
            console.log('error ', error)
            dispatch(sendContactMessageError(error));
        })
    }
};

export const sendContactMessageRequest = () => {
    return {
        type: 'SEND_CONTACT_MESSAGE_REQUEST'
    }
};

export const sendContactMessageError = (error) => {
    return {
        type: 'SEND_CONTACT_MESSAGE_ERROR',
        payload: error
    }
};

export const sendContactMessageSuccess = (data) => {
    return {
        type: 'SEND_CONTACT_MESSAGE_SUCCESS',
        payload: data
    }
};

export const setPackageSelect = (data) => {
    return {
        type: 'SET_PACKAGE_SELECTED',
        payload: data
    }
};

// SUBSCRIBE USER
export const subscribeUser = (data) => {
    console.log('data24: ', data)
    // const { packageKey } = data

    //console.log(user)
    return (dispatch) => {
        dispatch(subscribeUserRequest());
        fetch(url + 'subscribeuser', {
            method: 'POST',
            body: JSON.stringify(data),
            origin: "*",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        //.then(res => console.log(res.headers))

        .then(res => res.json())
        //.then(res => console.log(res))
        .then(res => {
            console.log('res: ', res)
            if (res.error) {
                //console.log(res)
                throw(res);
            }
            dispatch(subscribeUserSuccess(res));
        })
        .catch(error => {
            console.log('error:  ' + error)
            dispatch(subscribeUserError(error));
        })
    }
};

export const subscribeUserRequest = () => {
    return {
        type: 'SUBSCRIBE_USER_REQUEST'
    }
};

export const subscribeUserSuccess = (data) => {
    return {
        type: 'SUBSCRIBE_USER_SUCCESS',
        payload: data
    }
};

export const subscribeUserError = (error) => {
    return {
        type: 'SUBSCRIBE_USER_ERROR',
        payload: error
    }
};

export const setRedirect = (val) => {
    return {
        type: 'SET_REDIRECT',
        payload: val
    }
};

export const setUserCurrency = (val) => {
    return {
        type: 'SET_USER_CURRENCY',
        payload: val
    }
};